import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://632e2b8d662c47449b4ecae7258633b6@o1271422.ingest.sentry.io/6507976",
  integrations: [new BrowserTracing()],
  allowUrls: [window.location.hostname],
  ignoreErrors: [
    "Object Not Found Matching Id"
  ],
  tracesSampleRate: 0.05,
});
